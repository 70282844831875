// Компонент - бегущая строка

import '../Marquee/Marquee.css';

export default function Marquee() {
  return(
    <div className="marquee__container">
      <p className="marquee__text">CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA * CAKES BY JULIA *</p>
    </div>
  )
}
